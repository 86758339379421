export default function formatDateToLongForm(dateString:any) {
  const options:any = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);

  // Subtract 2 hours
  date.setHours(date.getHours() - 2);

  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

import jwt_decode from 'jwt-decode';
import { storage } from './storage';

interface DecodedToken {
  id: string;

}

export const getUserIdFromToken = (): string | null => {
  try {
    const token = storage.getToken();
    if (!token) {
      throw new Error('No token found');
    }

    const decoded: DecodedToken = jwt_decode(token);

    return decoded.id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

import baseApi from '@/core/lib/base';

import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { ClientType } from '../../clients/api/type';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        createGps: builder.mutation<ResponseModel<null>, ClientType>({
            query: payload => ({
                method: 'POST',
                url: '/gps',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Gps created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        GetGps: builder.query<
            ResponseModel<ClientType>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                role?: string;
                status?: string;
                occupation?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    role,
                    occupation,
                } = queryParameters;

                // Filter out null, undefined, or empty parameters
                const params: Record<string, any> = {};

                if (pageNumber !== undefined) {
                    params.pageNumber = pageNumber;
                }

                if (pageSize !== undefined) {
                    params.pageSize = pageSize;
                }

                if (from !== null && from !== '') {
                    params.from = from;
                }

                if (to !== null && to !== '') {
                    params.to = to;
                }

                if (search !== null && search !== '') {
                    params.search = search;
                }

                if (status !== null && status !== '') {
                    params.status = status;
                }

                if (role !== null && role !== '') {
                    params.role = role;
                }

                if (occupation !== null && occupation !== '') {
                    params.occupation = occupation;
                }

                return {
                    method: 'GET',
                    url: '/gps',
                    params,
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        UpdateGps: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/gps/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success(' Gps Updated Successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteGps: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/gps/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('Gps changed ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useCreateGpsMutation,
    useDeleteGpsMutation,
    useGetGpsQuery,
    useUpdateGpsMutation,
} = authApi;

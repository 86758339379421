import jwtDecode from "jwt-decode";
import { storage } from "./storage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function checkToken() {
    const storedToken: any = storage.getToken();

    if (!storedToken) {
       
        return;
    }

    try {
        const { exp }: any = jwtDecode(storedToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        const navigate = useNavigate()
        if (currentTimestamp > exp) {
            storage.removeToken();
            toast.error('Session expired. Please log in again.');
            navigate('/login')
        }
    } catch (error) {
        toast.error('Invalid token. Please log in again.');
        storage.removeToken();
    }
}

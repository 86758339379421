import { UseFormRegisterReturn } from 'react-hook-form';
import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';


export default function SelectField(props:any) {
    const {
        label,
        icon,
        placeholder = '',
        error,
        defaultValue='',
        registration,
        isLoading,
        className = '',
        onChange,
        options
    } = props;

    return (
        <FieldWrapper label={label}  error={error}>
            <div className="relative flex flex-col  w-full flex-wrap items-start  ">
                <select
                    autoComplete="off"
                    disabled={isLoading}
                    onChange={onChange}
                    defaultValue={defaultValue
                    }
                    className={clsx(
                        'bg-gray-100 font-[300] rounded-[8px] p-3 outline-none w-full text-gray-500',
                        icon ? 'px-0' : 'pl-3',
                        className
                    )}
                    
                    {...registration}
                >
                    <option value="" className="text-gray-800 ">{placeholder}</option>
                    {options?.map((item:any, index:any) => <option key={index} value={item.value} className='capitalize'>{item.text}</option> )}
                </select>
                

            </div>
        </FieldWrapper>
    );
}

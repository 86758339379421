import { UserModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	users:UserModel | null 
}

const initialState: InitialState = {
	users: null,
};

export const usersSlice = createSlice({
	initialState,
	name: 'usersSlice',
	reducers: {
		setUsers: (state, action: PayloadAction<UserModel>) => {
			

			state.users = action.payload;
		},
		
	},
});

import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment, useEffect } from 'react';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { useCreateVehicleMutation } from './api';
import { useGetClientsQuery } from '../clients/api';
import { storage } from '@/utils';
import { useForm } from 'react-hook-form';
import AppSelect from '@/components/select/select2';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
    plateNumber: z.string().min(1, 'Plate Number is required'),
    model: z.string().min(1, 'Vehicle model is required'),
    manufacturedYear: z.string().min(1, 'Manufacture year is required'),
    clientId: z.string().min(1, 'Client is required'),
});

export type RegisterInput = z.infer<typeof schema>;

export const AddVehicle = ({ handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const [clients, setClients] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegisterInput>({ resolver: zodResolver(schema) });

    const queryParams = new URLSearchParams(window.location.search);
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = 10000000;
    const active = 'true';

    const {
        isLoading: loadingClients,
        refetch,
        data,
    }: any = useGetClientsQuery(
        {
            pageNumber,
            pageSize,
            active
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    useEffect(() => {
        setClients(data?.data?.list ||data?.list);
    }, [data]);

    const [Oncreate, { isSuccess, isLoading }] = useCreateVehicleMutation();

    const onSubmit = async (data: any) => {
        await Oncreate(data);
        await handleRefetch();
        setModal1(false);
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary btn-sm m-1"
                >
                    <IconPlus className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                    Add Vehicle
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-gray-100 dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row font-bold justify-center w-full ">
                                            Register Client Vehicle
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            className="mt-10"
                                        >
                                            <div className="gap-2 grid grid-cols-2">
                                                <AppSelect
                                                    label="Owner (client)"
                                                    name="clientId"
                                                    placeholder="Select Client"
                                                    options={clients?.map(
                                                        (client: any) => ({
                                                            value: client.id,
                                                            label: `${client.lastName} ${client.firstName}`,
                                                        })
                                                    )}
                                                    error={
                                                        errors.clientId?.message
                                                    }
                                                    register={register}
                                                    setValue={setValue}
                                                    validation={{
                                                        required:
                                                            'Client is required',
                                                    }}
                                                />
                                                <InputField
                                                    type="text"
                                                    label="Plate Number"
                                                    placeholder="Plate Number"
                                                    loading={isLoading}
                                                    focus
                                                    error={errors.plateNumber}
                                                    registration={register(
                                                        'plateNumber'
                                                    )}
                                                />

                                                <InputField
                                                    type="text"
                                                    label="Vehicle model"
                                                    placeholder="Model"
                                                    loading={isLoading}
                                                    focus
                                                    error={errors.model}
                                                    registration={register(
                                                        'model'
                                                    )}
                                                />
                                                <InputField
                                                    type="year"
                                                    label="Manufacture Year"
                                                    placeholder="Ex. 2019"
                                                    loading={isLoading}
                                                    focus
                                                    error={
                                                        errors.manufacturedYear
                                                    }
                                                    registration={register(
                                                        'manufacturedYear'
                                                    )}
                                                />
                                            </div>
                                            <div className="flex justify-end items-center mt-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger mx-2"
                                                    onClick={() =>
                                                        setModal1(false)
                                                    }
                                                >
                                                    Discard
                                                </button>
                                                {isLoading ? (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Register
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import baseApi from '@/core/lib/base';
import config from '@/config';
import { useDispatch, useSelector } from 'react-redux';
import { TypedUseSelectorHook } from 'react-redux';
import themeConfigSlice from './themeConfigSlice';
import { userSlice } from '@/app/auth/redux/user';
import { authApi } from '@/app/auth/redux';
import { usersSlice } from '@/app/auth/redux/user/usersSlice';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    userState: userSlice.reducer,
    usersState: usersSlice.reducer,
    
    [authApi.reducerPath]: authApi.reducer,
});

const middlewares: any = [baseApi.middleware];

export const store = configureStore({
    reducer: rootReducer,
    devTools: config.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(middlewares),
});

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

import AdminLayout from '@/components/Layouts/Admin/DefaultLayout';
import { Navigate, useRoutes } from 'react-router-dom';
import { RoleProtector } from '@/core/protector';
import Error404 from '@/app/errors/4004Error';
import Widgets from '@/app/dashboard/widgets';
import Login from '@/app/auth/login';
import Users from '@/app/dashboard/users';
import Clients from '@/app/dashboard/clients';
import Vehicles from '@/app/dashboard/vehicles';
import Gps from '@/app/dashboard/gps';
import Subscriptions from '@/app/dashboard/subscriptions';

export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },

        { path: '*', element: <Error404 /> },
        {
            path: '/admin',
            element: <RoleProtector role="admin" element={<AdminLayout />} />,
            children: [
                { index: true, element: <Widgets /> },
                {
                    path: 'users',
                    element: <Users />,
                },
                {
                    path: 'clients',
                    element: <Clients />,
                },
                {
                    path: 'Vehicles',
                    element: <Vehicles />,
                },
                {
                    path: 'gps',
                    element: <Gps />,
                },
                {
                    path: 'subscriptions',
                    element: <Subscriptions />,
                },
            ],
        },
    ]);
}

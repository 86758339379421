import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import logo from "../../../assets/images/logo.png";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import AnimatedContainer from "@/components/container/animatedContainer";
import { useLoginMutation } from "../redux";
import { storage } from "@/utils";


export const Login = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userState);
  const [onLogin, { isSuccess, isLoading, isError, error }] =
    useLoginMutation();

    const token = storage.getToken()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

 useEffect(() => {
   
 }, [])
 

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin");
    }
  }, [isSuccess, navigate]);

  return (
    <AnimatedContainer>
      <main className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
          <div className="mb-5 text-center">
            <img src={logo} alt="Logo" className="mx-auto  h-24" />
            <h1 className="text-3xl font-extrabold uppercase leading-snug text-primary md:text-4xl">
              Sign in
            </h1>
            <p className="text-base font-bold leading-normal text-gray-600 dark:text-gray-300">
              Enter your email and password to login
            </p>
          </div>

          <form onSubmit={handleSubmit(onLogin)} className="space-y-4">
            {isError && (
              <motion.div
                initial={{ y: "-10px", opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="py-2 border-l-4 flex items-center gap-2 w-full mb-2 px-4 border-red-500 bg-red-200 text-red-500 dark:bg-red-700 dark:text-red-200"
              >
                <HiOutlineExclamationCircle className="text-red-500" />
                {error ? (error as any).data?.message : null}
              </motion.div>
            )}
            <div>
              <label htmlFor="username" className="block text-gray-700 dark:text-gray-200">
                Email 
              </label>
              <input
                type="email"
                {...register("email", {
                  required: "Email is required",
                })}
                placeholder="ex. johndoe@mail.com"
                className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-4 py-3 w-full rounded focus:outline-none"
              />
              {errors.email?.message && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="block text-gray-700 dark:text-gray-200">
                Password
              </label>
              <input
                type="password"
                {...register("password", {
                  required: "Password is required",
                })}
                placeholder="Enter your password"
                className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-200 px-4 py-3 w-full rounded focus:outline-none"
              />
              {errors.password?.message && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.password.message}
                </p>
              )}
            </div>

            <button
              className="bg-primary text-white font-bold py-3 w-full rounded hover:bg-primary-dark focus:outline-none"
              type="submit"
            >
              {isLoading ? "Loading..." : "Sign In"}
            </button>

            
          </form>
        </div>
      </main>
    </AnimatedContainer>
  );
};

export default Login;

import { useEffect, useState } from 'react';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, useAsyncError } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import { useGetSubscriptionQuery } from './api';
import { AddSubscription } from './Add';
import { UpdateSubscription } from './Update';
import { DeleteSubscription } from './Delete';
import { SubscriptionType } from './api/type';
import Filter from '@/components/filters/Filter';
import { useGetClientsQuery } from '../clients/api';

const Subscriptions = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();
    const [clientId, setClientId] = useState('');
    const [clients, setClients] = useState([]);

    const { isLoading: loadingClients, data: clientsData }: any =
        useGetClientsQuery(
            {
                pageNumber,
                pageSize,
            },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    useEffect(() => {
        setClients(clientsData?.data?.list || []);
    }, [clientsData]);


    const { isLoading, refetch, isFetching, data }: any =
        useGetSubscriptionQuery(
            {
                pageNumber,
                pageSize,
                from,
                status,
                to,
                search,
                clientId
            },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    const resetFilters = () => {
        setClientId('')
        setStatus('');
        setStartDateFilter('');
        setEndDateFilter('');
    };

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, refetch]);

    function getStatusBadgeClass(status: any) {
        switch (status) {
            case 'SUSPENDED':
                return 'bg-warning';
            case 'INACTIVE':
                return 'bg-danger';
            case 'ACTIVE':
                return 'bg-success';
            default:
                return '';
        }
    }
    const columns: TableColumn<SubscriptionType>[] = [
        {
            title: 'From date',
            accessor: 'startDate',
            render: row => (
                <p className="capitalize">
                    {formatDateToLongForm(row.startDate)}
                </p>
            ),
        },
        {
            title: 'To date',
            accessor: 'endDate',
            render: row => <p>{formatDateToLongForm(row.endDate)} </p>,
        },
        {
            title: 'GPS serial Number ',
            accessor: 'gps.serialNumber',
            render: row => <p>{row.gps.serialNumber} </p>,
        },
        {
            title: 'Gps SimCard ',
            accessor: 'gps.simCardNumber',
            render: row => <p>{row.gps.simCardNumber} </p>,
        },
        {
            title: ' Vehicle Plate Number  ',
            accessor: 'vehicle.plateNumber',
            render: row => <p>{row.vehicle.plateNumber} </p>,
        },
        {
            title: 'Vehicle Model ',
            accessor: 'vehicle.model',
            render: row => <p>{row.vehicle.model} </p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <span className={`badge ${getStatusBadgeClass(row?.status)}`}>
                    {row?.status}
                </span>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'createdAt',
            render: row => <p>{formatDateToLongForm(row?.createdAt)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateSubscription
                        subscription={row}
                        handleRefetch={handleRefetch}
                    />
                    <DeleteSubscription
                        subscription={row}
                        handleRefetch={handleRefetch}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(setPageTitle('Subscriptions'));
    }, [dispatch]);

    return (
        <div className="p-3">
            <div className="flex flex-row justify-between items-center my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link
                            to="/admin"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Subscriptions</span>
                    </li>
                </ul>
                <AddSubscription handleRefetch={handleRefetch} />
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.data?.list ?? data?.list ?? []}
                total={data?.data?.total ?? 0}
                tableName="Subscriptions"
                currentPage={data?.data?.currentPage ?? 1}
                nextPage={data?.data?.nextPage ?? 1}
                previousPage={data?.data?.previousPage ?? 1}
                lastPage={data?.data?.lastPage ?? 1}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter subscriptions"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="flex flex-col mb-4">
                                        <label htmlFor="clientId">
                                            Owner (client):
                                        </label>
                                        <select
                                            id="clientId"
                                            className="form-select"
                                            value={clientId}
                                            onChange={e =>
                                                setClientId(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select Client
                                            </option>
                                            {clients.map((client: any) => (
                                                <option
                                                    key={client.id}
                                                    value={client.id}
                                                >
                                                    {client.lastName}{' '}
                                                    {client.firstName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">
                                            Filter status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="status"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select status
                                            </option>

                                            <option value="ACTIVE">
                                                Active
                                            </option>
                                            <option value="INACTIVE">
                                                Inactive
                                            </option>
                                            <option value="SUSPENDED">
                                                Suspended
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Subscriptions;

import baseApi from '@/core/lib/base';

import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';
import { LoginInput, LoginModel, RegisterInput } from './types';
import { UserModel, userSlice } from './user';
import { usersSlice } from './user/usersSlice';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<ResponseModel<LoginModel>, LoginInput>({
            query: payload => ({
                method: 'POST',
                url: 'auth/login',
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    
                    dispatch(userSlice.actions.setUser(data));
                    storage.setToken(data.accessToken);
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        whoami: builder.query<ResponseModel<UserModel>, { id: string }>({
          query: ({ id }) => ({
            method: 'GET',
            url: `/users/${id}`,
          }),
          onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
            try {
              const { data }: any = await queryFulfilled;
              dispatch(userSlice.actions.setUser(data));
            } catch (error) {
              console.error('Error fetching user:', error);
            }
          },
        }),
    

        AdminSign: builder.mutation<ResponseModel<null>, RegisterInput>({
            query: payload => ({
                method: 'POST',
                url: '/users',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('User created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        getUsers: builder.query<
        ResponseModel<UserModel>,
        {
            pageNumber?: number;
            pageSize?: number;
            from?: string;
            to?: string;
            search?: string;
            role?: string;
            active?: string;
        }
    >({
        query: queryParameters => {
            const {
                pageNumber,
                pageSize,
                from,
                to,
                search,
                active,
                role
            } = queryParameters;
    
            // Remove undefined or empty parameters
            const params: Record<string, any> = {};
            if (pageNumber !== undefined) params['pageNumber'] = pageNumber;
            if (pageSize !== undefined) params['pageSize'] = pageSize;
            if (from) params['from'] = from;
            if (to) params['to'] = to;
            if (search) params['search'] = search;
            if (active) params['active'] = active;
            if (role) params['role'] = role;
    
            return {
                method: 'GET',
                url: '/users',
                params: params, // Include only defined parameters
            };
        },
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
            try {
                const { data }: any = await queryFulfilled;
    
                dispatch(usersSlice.actions.setUsers(data));
            } catch (error) {
                throw error;
            }
        },
    }),
    
        UpdateUser: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/users/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success('User  Updated successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteUser: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/users/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('User status changed ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        checkToken: builder.mutation<ResponseModel<null>, string>({
            query: token => ({
                method: ' post',
                url: `/api/v1/user/check-token/${token}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    storage.removeToken();
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useWhoamiQuery,

    useGetUsersQuery,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useAdminSignMutation,

    useCheckTokenMutation,
} = authApi;

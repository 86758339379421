import baseApi from '@/core/lib/base';

import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { ClientType } from '../../clients/api/type';


export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
    

        createSubscriptions: builder.mutation<ResponseModel<null>, ClientType>({
            query: payload => ({
                method: 'POST',
                url: '/subscriptions',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Subscriptions created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getSubscription: builder.query<
            ResponseModel<ClientType>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                role?: string;
                status?: string;
                clientId?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    role,
                    clientId,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/subscriptions',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        role,
                        status,
                        clientId,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                     await queryFulfilled;

                } catch (error) {
                    throw error;
                }
            },
        }),
        updatesubscriptions: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/subscriptions/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success('Subscriptions Updated Successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteSubscriptions: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/subscriptions/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('Subscriptions removed ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
       
    }),
});

export const {
useCreateSubscriptionsMutation,
useDeleteSubscriptionsMutation,
useGetSubscriptionQuery,
useUpdatesubscriptionsMutation
} = authApi;

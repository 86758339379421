import Select from 'react-select';
import { useEffect } from 'react';

interface SelectProps {
    label: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    register: any;
    name: string;
    className?: string;
    defaultValue?: { label: string; value: string };
    setValue: any;
    validation?: any;
    options: { label: string; value: string }[];
}

export default function AppSelect({
    label,
    error,
    register,
    validation,
    setValue,
    name,
    defaultValue,
    options,
    placeholder,
    required,
}: SelectProps) {

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue.value);
        }
    }, [defaultValue, setValue, name]);

    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor="" className='text-sm'>{required ? '*' : ' '} {label}</label>
            <Select
                {...register(name, validation)}
                onChange={(val: any) => setValue(name, val.value)}
                options={options}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={`bg-gray-100 font-light rounded-lg px-3 py-1 mt-0 outline-none w-full text-gray-500 ${error ? 'border-red-500' : ''}`}
                classNamePrefix="react-select"
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        border: 'none', 
                        background: '#F3F4F6',
                        boxShadow: 'none',
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#E5E7EB' : 'white', 
                        color: state.isSelected ? 'black' : 'gray', 
                    }),
                    menu: (provided) => ({
                        ...provided,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                    }),
                }}
            />
            {error && <p className="text-xs text-red-500">{error}</p>}
        </div>
    );
}

import baseApi from '@/core/lib/base';
import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { VehicleType } from './type';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
    

        createVehicle: builder.mutation<ResponseModel<null>, VehicleType>({
            query: payload => ({
                method: 'POST',
                url: '/vehicles',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Vehicle created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getVehicles: builder.query<
            ResponseModel<VehicleType>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                role?: string;
                clientId?: string;
                status?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    clientId,
                    role,
                    status,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/vehicles',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        role,
                        clientId,
                        status,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                     await queryFulfilled;

                } catch (error) {
                    throw error;
                }
            },
        }),
        UpdateVehicle: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/vehicles/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success(' Vehicle Updated Successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteVehicle: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/Vehicles/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('Vehicle status changed ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
       
    }),
});

export const {
    useCreateVehicleMutation,
    useDeleteVehicleMutation,
    useGetVehiclesQuery,
    useUpdateVehicleMutation
} = authApi;

import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import IconEdit from '@/components/Icon/IconEdit';
import { useUpdateUserMutation } from '@/app/auth/redux';
import IconPencilPaper from '@/components/Icon/IconPencilPaper';

const schema = z.object({
    firstName: z.string().min(1, 'First name is  required'),
    lastName: z.string().min(1, 'Last Name  is  required'),
  
    email: z.string().email().min(1, 'Email is  required'),
   
});

export type EditUserInput = z.TypeOf<typeof schema>;

export const UpdateUser = ({user,handleRefetch}:any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useUpdateUserMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-warning btn-sm"
                >
                    <IconPencilPaper className="" />
                  
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-gray-100 dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex font-bold flex-row justify-center w-full ">
                                            Update User
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<EditUserInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload) => {
                                              await OnPost({ id: user.id, data: payload });
                                            
                                           
                                                setModal1(false);
                                            
                                              await handleRefetch()
                                              
                                          }}
                                            className="mt-10  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="First Name"
                                                            placeholder="Ex. John"
                                                            defaultValue={user?.firstName}
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .firstName
                                                            }
                                                            registration={register(
                                                                'firstName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Last Name"
                                                            defaultValue={user?.lastName}
                                                            placeholder="Exx. Doe"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .lastName
                                                            }
                                                            registration={register(
                                                                'lastName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Email"
                                                            defaultValue={user?.email}
                                                            placeholder="Email"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                     
                                                       
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

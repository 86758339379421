import { useEffect, useState } from 'react';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import { useGetGpsQuery } from './api';
import { GpsType } from './api/type';
import { DeleteGps } from './DeleteGps';
import { UpdateGps } from './UpdateGps';
import { AddGps } from './AddGps';
import Filter from '@/components/filters/Filter';


const Gps = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
     const [status, setStatus] = useState('')
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();

    const { isLoading, refetch, isFetching, data }: any = useGetGpsQuery(
        {
            pageNumber,
            pageSize,
            from,
            status,
            to,
            search,
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    
    const resetFilters = () => {
        setStatus('')
        setStartDateFilter('');
        setEndDateFilter('');
    };

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, refetch]);

    function getStatusBadgeClass(status:any) {
        switch (status) {
            case 'AVAILABLE':
                return 'bg-success';
            case 'IN_USE':
                return 'bg-warning';
            case 'DISSABLE':
                return 'bg-danger';
            default:
                return '';
        }
    }
    const columns: TableColumn<GpsType>[] = [
        {
            title: 'Serial Number',
            accessor: 'serialNumber',
            render: row => <p className="capitalize">{row.serialNumber}</p>,
        },
        {
            title: 'SimCard Number',
            accessor: 'simCardNumber',
            render: row => <p>{row.simCardNumber} </p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: (row) => (
                <span className={`badge ${getStatusBadgeClass(row?.status)}`}>
                    {row?.status}
                </span>
            ),
        },
    

        {
            title: 'Date Created  ',
            accessor: 'createdAt',
            render: row => <p>{formatDateToLongForm(row?.createdAt)}</p>,
        },
        
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateGps Gps={row} handleRefetch={handleRefetch} />
                    <DeleteGps Gps={row} handleRefetch={handleRefetch} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(setPageTitle('GPS'));
    }, [dispatch]);

    return (
        <div className="p-3">
            <div className="flex flex-row justify-between items-center my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link
                            to="/admin"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>GPS</span>
                    </li>
                </ul>
                <AddGps handleRefetch={handleRefetch} />
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.data?.list ?? data?.list ?? []}

                total={data?.data?.total ?? 0}
                tableName="GPS"
                currentPage={data?.data?.currentPage?? 1}
                nextPage={data?.data?.nextPage?? 1}
                previousPage={data?.data?.previousPage?? 1}
                lastPage={data?.data?.lastPage?? 1}
                isLoading={isLoading}
                filterComponent={
                
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter GPS"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                 
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">
                                            Filter status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="status"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select status
                                            </option>
                                            <option value="AVAILABLE">
                                                Available
                                            </option>
                                            <option value="IN_USE">
                                                In use
                                            </option>
                                            <option value="DISSABLE">
                                                Disabled
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Gps;

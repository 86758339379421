import { useWhoamiQuery } from '@/app/auth/redux';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { getUserIdFromToken } from '@/utils/decode';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';




export const RoleProtector = ({ element, role } : { element: JSX.Element, role: string }): JSX.Element => {
    const [userId, setUserId] = useState<string >('');

    useEffect(() => {
      const id:any = getUserIdFromToken();
      setUserId(id);
    }, []);
    const token = storage.getToken

    const { isLoading, data } = useWhoamiQuery({ id: userId }, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });

    const { user } = useAppSelector((state) => state.userState);
   
    if (!token && !user) return <Navigate to={'/login'} />;


    return element;
};

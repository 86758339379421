import { useEffect, useState } from 'react';
import { FaUsers, FaCar, FaMapMarkedAlt, FaRegListAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ReactApexChart from 'react-apexcharts';
import { useGetStisticsDailyQuery, useGetStisticsMontlyQuery, useGetStisticsYearlyQuery, useGetTotalReportsQuery } from './api';
import { storage } from '@/utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Widgets(this: any) {
    
    const { data: totalReportsData }: any = useGetTotalReportsQuery({}, { skip: !storage.getToken(), refetchOnMountOrArgChange: true });
    const { data: dailyData }: any = useGetStisticsDailyQuery({}, { skip: !storage.getToken(), refetchOnMountOrArgChange: true });
    const { data: monthlyData }: any = useGetStisticsMontlyQuery({}, { skip: !storage.getToken(), refetchOnMountOrArgChange: true });
    const { data: yearlyData }: any = useGetStisticsYearlyQuery({}, { skip: !storage.getToken(), refetchOnMountOrArgChange: true });

    const [filter, setFilter] = useState('daily');
    const [chartData, setChartData] = useState<any>({ clients: [], subscriptions: [], categories: [] });

    useEffect(() => {
        if (filter === 'daily' && dailyData) {
            const clients = Object.values(dailyData).map((day: any) => day.clients);
            const subscriptions = Object.values(dailyData).map((day: any) => day.subscriptions);
            const categories = Object.keys(dailyData);
            setChartData({ clients, subscriptions, categories });
        } else if (filter === 'monthly' && monthlyData) {
            const clients = Object.values(monthlyData).map((day: any) => day.clients);
            const subscriptions = Object.values(monthlyData).map((day: any) => day.subscriptions);
            const categories = Object.keys(monthlyData);
            setChartData({ clients, subscriptions, categories });
        } else if (filter === 'yearly' && yearlyData) {
            const clients = Object.values(yearlyData).map((month: any) => month.clients);
            const subscriptions = Object.values(yearlyData).map((month: any) => month.subscriptions);
            const categories = Object.keys(yearlyData);
            setChartData({ clients, subscriptions, categories });
        }
    }, [filter, dailyData, monthlyData, yearlyData]);

    const data1:any = {
        series: [
            {
                name: 'Clients',
                data: chartData.clients,
            },
            {
                name: 'Subscriptions',
                data: chartData.subscriptions,
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 320,
                events: {
                    mounted: (chart: any) => {
                        chart.windowResizeHandler();
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '80%',
                },
            },
            grid: {
                borderColor: '#f2f5f7',
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#C24B28', '#805dca'],
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: chartData.categories,
                labels: {
                    show: true,
                    style: {
                        colors: '#8c9097',
                        fontSize: '11px',
                        fontWeight: 200,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'Numbers',
                    style: {
                        color: '#8c9097',
                    },
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#8c9097',
                        fontSize: '11px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
          
        },
    };


  

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
                <Link to="users" className="w-full">
                    <div className="bg-white dark:bg-  dark:text-white text-gray-800 rounded-lg shadow-md overflow-hidden">
                        <div className="px-4 py-6 flex items-center">
                            <div className="bg-red-500 p-2 rounded-full">
                                <FaUsers className="text-white text-2xl" />
                            </div>
                            <div className="ml-3">
                                <h2 className="text-sm font-semibold mb-1">Total Users</h2>
                                <p className="text-md font-bold">{totalReportsData?.stats?.totalUsers ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="clients" className="w-full">
                    <div className="bg-white text-gray-800 rounded-lg shadow-md overflow-hidden">
                        <div className="px-4 py-6 flex items-center">
                            <div className="bg-green-500 p-2 rounded-full">
                                <FaUsers className="text-white text-2xl" />
                            </div>
                            <div className="ml-3">
                                <h2 className="text-sm font-semibold mb-1">Total Clients</h2>
                                <p className="text-md font-bold">{totalReportsData?.stats?.totalClients ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="vehicles" className="w-full">
                    <div className="bg-white text-gray-800 rounded-lg shadow-md overflow-hidden">
                        <div className="px-4 py-6 flex items-center">
                            <div className="bg-purple-500 p-2 rounded-full">
                                <FaCar className="text-white text-2xl" />
                            </div>
                            <div className="ml-3">
                                <h2 className="text-sm font-semibold mb-1">Total Vehicles</h2>
                                <p className="text-md font-bold">{totalReportsData?.stats?.totalVehicles ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="gps" className="w-full">
                    <div className="bg-white text-gray-800 rounded-lg shadow-md overflow-hidden">
                        <div className="px-4 py-6 flex items-center">
                            <div className="bg-yellow-500 p-2 rounded-full">
                                <FaMapMarkedAlt className="text-white text-2xl" />
                            </div>
                            <div className="ml-3">
                                <h2 className="text-sm font-semibold mb-1">Total GPS</h2>
                                <p className="text-md font-bold">{totalReportsData?.stats?.totalGps ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="subscriptions" className="w-full">
                    <div className="bg-white text-gray-800 rounded-lg shadow-md overflow-hidden">
                        <div className="px-4 py-6 flex items-center">
                            <div className="bg-blue-500 p-2 rounded-full">
                                <FaRegListAlt className="text-white text-2xl" />
                            </div>
                            <div className="ml-3">
                                <h2 className="text-sm font-semibold mb-1">Total Subscriptions</h2>
                                <p className="text-md font-bold">{totalReportsData?.stats?.totalSubscriptions ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="mt-8 panel  mx-3">
                <div>
                    <h2 className='font-bold text-lg'>Clients and subscriptions chart</h2>
                </div>
                <div className="flex justify-end mb-4">
                    <button className={`px-4 py-2 mr-2 rounded ${filter === 'daily' ? 'bg-primary text-white' : 'bg-gray-200'}`} onClick={() => setFilter('daily')}>Weekly</button>
                    <button className={`px-4 py-2 mr-2 rounded ${filter === 'monthly' ? 'bg-primary text-white' : 'bg-gray-200'}`} onClick={() => setFilter('monthly')}>Monthly</button>
                    <button className={`px-4 py-2 rounded ${filter === 'yearly' ? 'bg-primary text-white' : 'bg-gray-200'}`} onClick={() => setFilter('yearly')}>Yearly</button>
                </div>
                
                <ReactApexChart options={data1.options} series={data1.series} type="bar" height={300} />

            </div>
        </div>
    );
}

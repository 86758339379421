import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form } from '@/components/form'; 
import { z } from 'zod';
import { CircularProgress } from '@mui/material';
import IconPencilPaper from '@/components/Icon/IconPencilPaper';
import { useUpdatesubscriptionsMutation } from './api';
import SelectField from '@/components/select/SelectField'; 
import { useGetVehiclesQuery } from '../vehicles/api'; 
import { storage } from '@/utils';
import { useGetGpsQuery } from '../gps/api'; 
import { InputField } from '@/components/input';


const schema = z.object({
    startDate: z.string().min(1, 'Start date is required'),
    endDate: z.string().min(1, 'End date is required'),
    status: z.string(),
});

export type EditClientInput = z.TypeOf<typeof schema>;

export const UpdateSubscription = ({ subscription, handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const [OnPost, { isSuccess, isLoading }] = useUpdatesubscriptionsMutation();

    const [vehicles, setVehicles] = useState<any[]>([]);
    const [GPSs, setGps] = useState<any[]>([]);

    const queryParams = new URLSearchParams(window.location.search);
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = 10000000;


    const formatDateForInput = (dateString: string) => {
        // Convert ISO string to YYYY-MM-DDTHH:MM format
        if (!dateString) return '';
        const date = new Date(dateString);
        const isoString = date.toISOString();
        return isoString.slice(0, 16); // Return YYYY-MM-DDTHH:MM format
    };

    const parseDateFromInput = (inputValue: string) => {
        // Convert YYYY-MM-DDTHH:MM format back to ISO string
        if (!inputValue) return '';
        return new Date(inputValue).toISOString();
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-warning btn-sm"
                >
                    <IconPencilPaper className="" />
                </button>
            </div>
            <Transition appear show={modal1} as={React.Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={React.Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-gray-100 dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex font-bold flex-row justify-center w-full">
                                            Update Subscription
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<EditClientInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload) => {
                                                const formattedPayload = {
                                                    ...payload,
                                                    startDate: parseDateFromInput(
                                                        payload.startDate
                                                    ),
                                                    endDate: parseDateFromInput(
                                                        payload.endDate
                                                    ),
                                                };
                                                await OnPost({
                                                    id: subscription.id,
                                                    data: formattedPayload,
                                                });
                                                setModal1(false);
                                                await handleRefetch();
                                            }}
                                            className="mt-10"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="datetime-local"
                                                            label="Subscription start date"
                                                            placeholder="date"
                                                            loading={isLoading}
                                                            defaultValue={
                                                                formatDateForInput(
                                                                    subscription.startDate
                                                                )
                                                            }
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .startDate
                                                            }
                                                            registration={register(
                                                                'startDate'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="datetime-local"
                                                            label="Subscription end Date"
                                                            defaultValue={
                                                                formatDateForInput(
                                                                    subscription.endDate
                                                                )
                                                            }
                                                            placeholder="date"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .endDate
                                                            }
                                                            registration={register(
                                                                'endDate'
                                                            )}
                                                        />
                                                     
                                                        <SelectField
                                                            name="status"
                                                            label="Status"
                                                            defaultValue={
                                                                subscription?.status
                                                            }
                                                            placeholder="Select Status"
                                                            options={[
                                                                {
                                                                    text: 'Active',
                                                                    value: 'ACTIVE',
                                                                },
                                                                {
                                                                    text: 'Inactive',
                                                                    value: 'INACTIVE',
                                                                },
                                                                {
                                                                    text: 'Suspended',
                                                                    value: 'SUSPENDED',
                                                                },
                                                            ]}
                                                            error={
                                                                formState.errors
                                                                    .status
                                                            }
                                                            registration={register(
                                                                'status'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2"
                                                            onClick={() =>
                                                                setModal1(false)
                                                            }
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                disabled={
                                                                    isLoading
                                                                }
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color:
                                                                            'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                disabled={
                                                                    isLoading
                                                                }
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

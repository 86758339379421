import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useDeleteUserMutation } from '@/app/auth/redux';
import IconTrash from '@/components/Icon/IconTrash';

export const DeleteUser = ({ user, handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [deleteUser, { isSuccess, isLoading }] = useDeleteUserMutation();

    const handleDelete = () => {
        deleteUser(user.id)
            .then(async () => {
                await handleRefetch();
                setModal1(false);
            })
            .catch((error: any) => {
                toast.error('deleting failed');
            });
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-danger btn-sm"
                >
                   Change status
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-gray-100  dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row  font-bold justify-center w-full  ">
                                            Change User Status
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <div className="">
                                            <p>
                                                Are You sure you want to change user {' '}
                                                <span className="font-bold capitalize">
                                                    {user?.firstName}   {user?.lastName}
                                                    
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger mx-2 "
                                                onSubmit={async () => {
                                                    setModal1(false);
                                                }}
                                            >
                                                Discard
                                            </button>
                                            {isLoading ? (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className=" btn btn-primary"
                                                    onClick={handleDelete}
                                                >
                                                    Change
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

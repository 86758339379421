import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { AddUser } from './AddUser';
import { UpdateUser } from './UpdateUser';
import { DeleteUser } from './DeleteUser';
import { UserModel } from '@/app/auth/redux/user';
import { useGetUsersQuery } from '@/app/auth/redux';

const Users = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [active, setActive] = useState('');
   
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();

    const { isLoading, refetch, isFetching, data }: any = useGetUsersQuery(
        {
            pageNumber,
            pageSize,
            from,
            active,
            to,
            search,
           
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    const { users } = useAppSelector((state: any) => state.usersState);
    const resetFilters = () => {
        setActive('')
        setStartDateFilter('');
        setEndDateFilter('');
    };

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, active, refetch]);

    const columns: TableColumn<UserModel>[] = [
        {
            title: 'First Name',
            accessor: 'firstName',
            render: row => <p className="capitalize">{row.firstName}</p>,
        },
        {
            title: 'last Name',
            accessor: 'lastName',
            render: row => <p>{row.lastName} </p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row.email} </p>,
        },

        {
            title: 'Role',
            accessor: 'role',
            render: row => <p className='capitalize'>{row.role} </p>,
        },
        {
            title: 'Date Created  ',
            accessor: 'createdAt',
            render: row => <p>{formatDateToLongForm(row?.createdAt)}</p>,
        },
        {
            title: 'Status',
            accessor: 'active',
            render: row => (
                <p>
                    {row?.active ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateUser user={row} handleRefetch={handleRefetch} />
                    <DeleteUser user={row} handleRefetch={handleRefetch} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(setPageTitle('Users'));
    }, [dispatch]);

    return (
        <div className="p-3">
            <div className="flex flex-row justify-between items-center my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link
                            to="/admin"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Users</span>
                    </li>
                </ul>
                <AddUser handleRefetch={handleRefetch} />
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.list ?? []}
                total={data?.total ?? 0}
                tableName="Users"
                currentPage={data?.currentPage?? 1}
                nextPage={data?.nextPage?? 1}
                previousPage={data?.previousPage?? 1}
                lastPage={data?.lastPage?? 1}
                isLoading={isLoading}
                filterComponent={
                
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter users"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">
                                            Filter status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="status"
                                            onChange={e =>
                                                setActive(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select status
                                            </option>
                                            <option value="true">
                                                Active
                                            </option>
                                            <option value="false">
                                                Inactive
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Users;

import { UserModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	user: UserModel | null;
}

const initialState: InitialState = {
	user: null,
};
console.log(initialState)
export const userSlice = createSlice({
	initialState,
	name: 'userSlice',
	reducers: {
		setUser: (state, action: PayloadAction<UserModel>) => {
			state.user = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});

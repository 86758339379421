import baseApi from '@/core/lib/base';

import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';
import { ClientType } from './type';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
    

        CreateClient: builder.mutation<ResponseModel<null>, ClientType>({
            query: payload => ({
                method: 'POST',
                url: '/clients',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('User created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getClients: builder.query<
            ResponseModel<ClientType>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                role?: string;
                active?: string;
                occupation?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    active,
                    role,
                    
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/clients',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        role,
                        active,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                     await queryFulfilled;

                } catch (error) {
                    throw error;
                }
            },
        }),
        UpdateClient: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/clients/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success(' Client Updated Successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteClient: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/clients/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('Client status changed ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
       
    }),
});

export const {
   useCreateClientMutation,
   useDeleteClientMutation,
   useGetClientsQuery,
   useUpdateClientMutation
} = authApi;

import { useEffect, useState } from 'react';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import { VehicleType } from './api/type';
import { useGetVehiclesQuery } from './api';
import { UpdateVehicle } from './updateVehicle';
import { AddVehicle } from './AddVehicle';
import { DeleteVehicle } from './DeleteVehicle';
import Filter from '@/components/filters/Filter';
import { useGetClientsQuery } from '../clients/api';

const Vehicles = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [clientId, setClientId] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();

    const { isLoading, refetch, isFetching, data }: any = useGetVehiclesQuery(
        {
            pageNumber,
            pageSize,
            from,
            to,
            clientId,
            status,
            search,
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
            console.error('Error refetching data:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    const resetFilters = () => {
        setStartDateFilter('');
        setEndDateFilter('');
        setClientId('');
        setStatus('');
    };

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, clientId, status, search, refetch]);

    function getStatusBadgeClass(status: any) {
        switch (status) {
            case 'ACTIVE':
                return 'bg-success';
            case 'INACTIVE':
                return 'bg-danger';
            case 'DISSABLED':
                return 'bg-primary';
            default:
                return '';
        }
    }

    const columns: TableColumn<VehicleType>[] = [
        {
            title: 'Plate Number',
            accessor: 'plateNumber',
            render: row => <p className="capitalize">{row.plateNumber}</p>,
        },
        {
            title: 'Vehicle model',
            accessor: 'model',
            render: row => <p>{row.model}</p>,
        },
        {
            title: 'Manufacture Year',
            accessor: 'manufacturedYear',
            render: row => <p>{row.manufacturedYear}</p>,
        },
        {
            title: 'Client Name',
            accessor: 'client.firstName',
            render: row => (
                <p>
                    {row.client?.firstName} {row.client?.lastName}
                </p>
            ),
        },
        {
            title: 'Client Email',
            accessor: 'client.email',
            render: row => <p>{row?.client?.email}</p>,
        },
        {
            title: 'Client Phone',
            accessor: 'client.phone',
            render: row => <p>{row?.client?.phone}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <span className={`badge ${getStatusBadgeClass(row?.status)}`}>
                    {row?.status}
                </span>
            ),
        },
        {
            title: 'Date Created',
            accessor: 'createdAt',
            render: row => <p>{formatDateToLongForm(row?.createdAt)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateVehicle vehicle={row} handleRefetch={handleRefetch} />
                    <DeleteVehicle vehicle={row} handleRefetch={handleRefetch} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(setPageTitle('Vehicles'));
    }, [dispatch]);

    const [clients, setClients] = useState([]);
    const { isLoading: loadingClients, data: clientsData }: any = useGetClientsQuery(
        {
            pageNumber,
            pageSize,
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    useEffect(() => {
        setClients(clientsData?.data?.list || []);
    }, [clientsData]);

    return (
        <div className="p-3">
            <div className="flex flex-row justify-between items-center my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link to="/admin" className="text-primary hover:underline">
                            Dashboard
                        </Link>
                    </li>
                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Vehicles</span>
                    </li>
                </ul>
                <AddVehicle handleRefetch={handleRefetch} />
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.data?.list ?? data?.list ?? []}
                total={data?.data?.total ?? 0}
                tableName="Vehicles"
                currentPage={data?.data?.currentPage ?? 1}
                nextPage={data?.data?.nextPage ?? 1}
                previousPage={data?.data?.previousPage ?? 1}
                lastPage={data?.data?.lastPage ?? 1}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Vehicles"
                        children={
                            <>
                                <div className="flex flex-col mb-4">
                                    <label htmlFor="clientId">Owner (client):</label>
                                    <select
                                        id="clientId"
                                        className="form-select"
                                        value={clientId}
                                        onChange={e => setClientId(e.target.value)}
                                    >
                                        <option value="">Select Client</option>
                                        {clients.map((client: any) => (
                                            <option key={client.id} value={client.id}>
                                                {client.lastName} {client.firstName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <label htmlFor="status">Status:</label>
                                    <select
                                        id="status"
                                        className="form-select"
                                        value={status}
                                        onChange={e => setStatus(e.target.value)}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="ACTIVE">Active</option>
                                        <option value="INACTIVE">Inactive</option>
                                        <option value="DISSABLED">Disabled</option>
                                    </select>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <label htmlFor="fromDate">From:</label>
                                    <input
                                        type="date"
                                        id="fromDate"
                                        className="form-input"
                                        value={from}
                                        onChange={e => setStartDateFilter(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col mb-4">
                                    <label htmlFor="toDate">To:</label>
                                    <input
                                        type="date"
                                        id="toDate"
                                        className="form-input"
                                        value={to}
                                        onChange={e => setEndDateFilter(e.target.value)}
                                    />
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Vehicles;

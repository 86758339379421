import baseApi from '@/core/lib/base';

import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
     
        getTotalReports: builder.query<ResponseModel<any>, {}>({
          query: () => ({
            method: 'GET',
            url: `/stats/total`,
          }),
          onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
            try {
              const { data }: any = await queryFulfilled;
            
            } catch (error) {
            console.log(error)
            }
          },
        }),
    
 
        getStisticsDaily: builder.query<ResponseModel<any>, {}>({
            query: () => ({
              method: 'GET',
              url: `/stats/weekly`,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
              try {
                const { data }: any = await queryFulfilled;
              
              } catch (error) {
              console.log(error)
              }
            },
          }),
          getStisticsMontly: builder.query<ResponseModel<any>, {}>({
            query: () => ({
              method: 'GET',
              url: `/stats/monthly`,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
              try {
                const { data }: any = await queryFulfilled;
              
              } catch (error) {
              console.log(error)
              }
            },
          }),
      
          getStisticsYearly: builder.query<ResponseModel<any>, {}>({
            query: () => ({
              method: 'GET',
              url: `/stats/yearly`,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
              try {
                const { data }: any = await queryFulfilled;
              
              } catch (error) {
              console.log(error)
              }
            },
          }),
     
    }),
});

export const {
   
    useGetStisticsDailyQuery,
    useGetStisticsMontlyQuery,
    useGetStisticsYearlyQuery,
    useGetTotalReportsQuery
 
} = authApi;

import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { useAdminSignMutation } from '@/app/auth/redux';
import { useCreateClientMutation } from './api';

const schema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    phone: z.string()
        .length(12, 'Phone number must be exactly 12 characters long')
        .refine((val) => val.startsWith('250'), {
            message: 'Phone number must begin with 250'
        }),
    email: z.string()
});
 

export type RegisterInput = z.TypeOf<typeof schema>;

export const AddClient = ({ handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [Oncreate, { isSuccess, isLoading }] = useCreateClientMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary btn-sm m-1"
                >
                    <IconPlus className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                    Add Client
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-gray-100 dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row font-bold justify-center w-full ">
                                            Register Client
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload: any) => {
                                                await Oncreate(payload);
                                                await handleRefetch();
                                                
                                                setModal1(false);
                                            }}
                                            className="mt-10  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="First Name"
                                                            placeholder="Ex .John"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .firstName
                                                            }
                                                            registration={register(
                                                                'firstName'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Last Name"
                                                            placeholder="Ex. Doe"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .lastName
                                                            }
                                                            registration={register(
                                                                'lastName'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="text"
                                                            label="Email"
                                                            placeholder="Email"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />

                                                      
                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            placeholder="ex. 2507xxxxxxxx"
                                                            loading={isLoading}
                                                            error={
                                                                formState.errors
                                                                    .phone
                                                            }
                                                            registration={register(
                                                                'phone'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Register
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
